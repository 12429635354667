import React from 'react';
import "../style/Home.css";
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'react-bootstrap';
import { useLottie } from "lottie-react";
import animationData2 from "../images/Verified-Neighbours.json";
import animationData3 from "../images/Vibe_with_your_neighbourhood.json";
import animationData4 from "../images/Neighbours-helping.json";
import animationData5 from "../images/local-services.json"

const Services = () => {
    const options1 = {
        animationData: animationData2,
        loop: true
    };
    const options2 = {
        animationData: animationData3,
        loop: true
    };

    const options3 = {
        animationData: animationData4,
        loop: true
    };
    const options4 = {
        animationData: animationData5,
        loop: true
    };


    const { View: View1 } = useLottie(options1);
    const { View: View2 } = useLottie(options2);
    const { View: View3 } = useLottie(options3);
    const { View: View4 } = useLottie(options4);

    return (
        <div className='services-intro-bg'>
            <section className='container'>
                <Row className='verified-neighbours d-flex align-items-center justify-content-center'>
                    <Col lg={4} xl={4} md={6} sm={12} xs={12} className='d-flex justify-content-center'>
                        <Card
                            style={{
                                width: '18rem',
                                background: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8%',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                            }}
                        >
                            <Card.Body
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                {View1}
                                <Card.Text>
                                    <h4 className="verified-text">Verified</h4>
                                    <h4 className="verified-text2">neighbours, Guaranteed.</h4>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={8} xl={8} md={6} sm={12} xs={12} className="d-flex">
                        <p className='security text-left'>
                            Your security is our top priority. Every <br></br>profile is carefully reviewed, adding an<br></br> extra level of assurance so you can trust <br></br>that the neighbours are genuine.
                        </p>
                    </Col>
                </Row>

                <Row className='verified-neighbours d-flex align-items-center justify-content-center vibe-row'>
                    <Col lg={6} xl={6} md={6} sm={12} xs={12} className='vibe-lottie'>
                        <Card
                            style={{
                                width: '18rem',
                                background: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8%',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                            }}
                        >
                            <Card.Body
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                
                                    {View2}
                        
                                <Card.Text>
                                    <h4 className="vibe-text">Vibe</h4>
                                    <h4 className="vibe-text2">with your</h4>
                                    <h4 className="vibe-text2">neighbourhood</h4>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} xl={6} md={6} sm={12} xs={12} className="d-flex justify-content-center ">
                        <p className='security '>
                            Your security is our top priority. Every <br></br>profile is carefully reviewed, adding an<br></br> extra level of assurance so you can trust <br></br>that the neighbours are genuine.
                        </p>
                    </Col>
                </Row>
                <Row className='verified-neighbours d-flex align-items-center justify-content-center vibe-row'>
                <Col lg={6} xl={6} md={6} sm={12} xs={12} className="d-flex justify-content-center">
                        <p className='security '>
                        A place where locals lend a <br></br>hand—and a ladder—and find<br></br> new homes for old sofas.
                        </p>
                    </Col>
                    <Col lg={6} xl={6} md={6} sm={12} xs={12} className='help-lottie'>
                        <Card
                            style={{
                                width: '18rem',
                                background: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8%',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                            }}
                        >
                            <Card.Body
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // alignItems: 'center',
                                    // justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                
                                    {View3}
                        
                                <Card.Text>
                                    <h4 className="vibe-text">Neighbours</h4>
                                    <h4 className="vibe-text2" style={{color:"#7323A8"}}>helping</h4>
                                    <h4 className="vibe-text2">neighbours</h4>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                
                </Row>
                <Row className='verified-neighbours d-flex align-items-center justify-content-center vibe-row'>
                  
                    <Col lg={8} xl={8} md={6} sm={12} xs={12} className="d-flex local-services-text">
                        <p className='security '>
                        Find reliable local services that your<br></br> neighbours recommend, whether<br></br> you need help from a handyman or<br></br> a baby-sitter.
                        </p>
                    </Col>
                    <Col lg={4} xl={4} md={6} sm={12} xs={12} className='vibe-lottie'>
                        <Card
                            style={{
                                width: '18rem',
                                background: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8%',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                            }}
                        >
                            <Card.Body
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                
                                    {View4}
                        
                                <Card.Text>
                                    <h4 className="discover-text">Discover</h4>
                                    <h4 className="vibe-text2"> trusted </h4>
                                    <h4 className="vibe-text2">local services</h4>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </div>
    );
}

export default Services;

import React from 'react'
import Header from '../components/Header'
import "../style/Home.css"
import { useState } from 'react'
import DoorPeIntro from '../components/DoorpeIntro'
import Services from '../components/Services'
import Reasons from '../components/Reasons'
import Footer from '../components/Footer'

const Home = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    console.log(selectedIndex)
  return (
    <div>
<div className='home-page-background'style={{
    background: selectedIndex === 0 
      ? 'linear-gradient(119deg, #DECCE7, #F0BABD)' 
      : selectedIndex === 1 
      ? 'linear-gradient(119deg, #984FC9, #CEA7E6)' 
      : selectedIndex === 2 
      ? 'linear-gradient(119deg, #EDB9BD, #F0D5BA)' 
      : 'linear-gradient(119deg, #F5E0FF, #FFFFFF)'
  }}
>


        <Header selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}/>
     
    </div>
   {/* <DoorPeIntro/> */}

<Services/>
<Reasons/>
<Footer/>
    </div>
  )
}

export default Home
import React from 'react'
import "../style/Home.css"
import {Row,Col} from 'react-bootstrap'

const Footer = () => {
  return (
    <div className='footer-bg'>
        <section className='container'>
            <div className='download-sec'>
                <h1 className='scroll-anymore'>You can’t scroll anymore.</h1>
                <p className='stop-scrolling'>Stop scrolling and start connecting—join DoorPe today</p>
                <div className='download-btn'>
                    <p className='download-text'>Download DoorPe App</p>
                    <img src="./images/playstore_icon.png" className='playstore-icon'/>
                    <img src="./images/appstore_icon.png" className='appstore-icon'/>
                </div>
                <div className='footer-logo-sec'>
                    <img src="./images/doorpe_footer_logo.png" className='doorpe-footer-img'/>
                </div>
            </div>
           <Row>
            <Col lg={2} xl={2} md={4} sm={4} xs={4} className='social-media-icons'>
            <Col>
            <img src="./images/facebook.png" className='facebook-icon'/>
            </Col>
            <Col>
            <img src="./images/Instagram.png" className='instagram-icon'/>
            </Col>
            <Col>
            <img src="./images/Twitter.png" className='twitter-icon'/>
            </Col>
            </Col>
           </Row>
           <Row className='policy-conditions'>
            <Col lg={6} xl={6} sm={12} md={6} xs={12}>
            <p className='rights'>©2024. All rights reserved. Cultural Links Technology LLC.</p></Col>
            <Col lg={6} xl={6} sm={12} md={6} xs={12} className='policy'>
            <p className="privacy-policy" style={{ textDecoration: 'underline' }}>
    Privacy Policy
</p>

                </Col>
           </Row>
        </section>
    </div>
  )
}

export default Footer
import React, { useEffect, useState } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css'; // Include Flickity CSS
import "../style/Home.css";
import DoorpeIntro from './DoorpeIntro';


const flickityOptions = {
  wrapAround: false,
  autoPlay: 3000,
  pauseAutoPlayOnHover: false,
  fade: false,
  prevNextButtons: false,
  pageDots: true,
  initialIndex: 0,
  groupCells: 1,
  cellAlign: 'center',
};

const HomePage = ({setSelectedIndex,selectedIndex}) => {
//   const [selectedIndex, setSelectedIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(3000); // Default autoPlay interval

  const updateDotStyles = (index) => {
    const dots = document.querySelectorAll('.flickity-page-dots .dot');
    dots.forEach((dot, i) => {
      dot.style.width = '14px';
      dot.style.height = '14px';

      if (i === index) {
        dot.style.width = '32px';
        dot.style.height = '32px';
      } else if (i === (index - 1 + dots.length) % dots.length) {
        dot.style.width = '25px';
        dot.style.height = '25px';
      } else if (i === (index - 2 + dots.length) % dots.length) {
        dot.style.width = '14px';
        dot.style.height = '14px';
      } else if (i === (index - 3 + dots.length) % dots.length) {
        dot.style.width = '8px';
        dot.style.height = '8px';
      }
    });
  };

  useEffect(() => {
    updateDotStyles(selectedIndex);

    if (selectedIndex === 3) {
      setAutoPlay(false); // Stop autoPlay when index reaches 3
    } else {
      setAutoPlay(3000); // Resume autoPlay for other slides
    }
  }, [selectedIndex]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const tiltX = (clientY / windowHeight - 0.5) * 65; // Calculate tilt along X axis
      const tiltY = (clientX / windowWidth - 0.5) * -65; // Calculate tilt along Y axis
console.log(clientX,clientY)
      const images = document.querySelectorAll('.carousel-cell img');
      images.forEach((image) => {
        image.style.transform = `rotateX(${tiltX}deg) rotateY(${tiltY}deg)`;
      });
    };

    // Add the event listener for mouse movement
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  

  return (
    <section className='doorpe-intro'>
      <div className="overflow-hidden">
        <div>
          <section>
            <Flickity options={{
              ...flickityOptions,
              autoPlay: autoPlay, // Use dynamic autoPlay
              on: {
                change: (index) => {
                  setSelectedIndex(index);
                  updateDotStyles(index);
                },
              },
            }}>
              {/* Slide 1 */}
              <div className="carousel-cell d-flex justify-content-center align-items-center" style={{ width: selectedIndex === 0 ? '60%' : '40%',marginTop:selectedIndex===0?'':'4%'  }}>
              <div className="carousel-content text-center">
                  <img
                    className="hello-beighboors"
                    src="./images/Doorpe_intro_image.png"
                    alt="First slide"
                    // style={{ width: selectedIndex === 0?'80%':'54%'}}
                    style={{
                        width: selectedIndex === 0 ? '80%' : '54%',
                        transform: selectedIndex === 0 ? 'rotate(0deg)' : 'rotate(10deg)',
                        // transition: 'transform 0.5s ease, width 0.5s ease' // Optional for smooth transition
                      }}
                  />
                  <h1 className={`welcome-text ${selectedIndex === 0 ? 'visible' : ''}`}>
                    Welcome to the local conversations!
                  </h1>
                </div>
              </div>

              {/* Slide 2 */}
              <div className="carousel-cell d-flex justify-content-center align-items-center" style={{ width: selectedIndex === 1 ? '60%' : '40%',marginTop:selectedIndex===1?'':'4%'  }}>
              <div className="carousel-content text-center">
                  <img
                    className="hello-beighboors"
                    src="./images/Image1.png"
                    alt="Second slide"
                    style={{ width: selectedIndex === 1?'80%':'44%'}}
                  />
                  <h1 className={`welcome-text2 ${selectedIndex === 1 ? 'visible' : ''}`}>
                  Discover & discuss<br></br>what truly matters around you
                  </h1>
                </div>
              </div>

              {/* Slide 3 */}
              <div className="carousel-cell d-flex justify-content-center align-items-center" style={{ width: selectedIndex === 2 ? '67%' : '45%',marginTop:selectedIndex===2?'':'2%' }}>
              <div className="carousel-content text-center">
                  <img
                    className="party-img"
                    src="./images/party.png"
                    alt="Third slide"
                    style={{ width: selectedIndex === 2?'100%':'54%'}}
                  />
                  <h1 className={`welcome-text3 ${selectedIndex === 2 ? 'visible' : ''}`}>
                  Seeb winter party barbeque
                  </h1>
                </div>
              </div>

              {/* Slide 4 */}
              <div className="carousel-cell d-flex justify-content-center align-items-center" style={{ width: selectedIndex === 3 ? '70%' : '30%',marginTop:selectedIndex===3?'1%':'4%' }}>
              <div className="carousel-content text-center">
                  <img
                    className="women-img"
                    src="./images/women.png"
                    alt="Fourth slide"
                    style={{ width: selectedIndex === 3?'50%':'36%'}}
                    
                  />
                  <h1 className={`welcome-text4 ${selectedIndex === 3 ? 'visible' : ''}`}>
                  Feel the good vibes<br></br>knowing you’ve made a difference
                  </h1>
                </div>
              </div>
            </Flickity>
          </section>
        </div>
      </div>
   
    </section>
  );
};

export default HomePage;

import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../style/Home.css"
import HomePage from './HomePage';
import { useState } from 'react';

const Header = ({selectedIndex,setSelectedIndex}) => {
    const handledownload = () => {
        // window.open("https://www.onelink.to/honc","_blank")
        // const userAgent = window.navigator.userAgent.toLowerCase();
    
        // if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
    
        //   window.open("https://www.onelink.to/honc", "_blank");
        // } else if (userAgent.includes('android')) {
    
        //   window.open("https://www.onelink.to/honc", "_blank");
        // } else {
    
        //   window.open("https://play.google.com/store/apps/details?id=com.honc_customer&hl=en_IN", "_blank");
        // }
      }
    
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    console.log(selectedIndex)
  return (
    <section  className=' navbar-fields overflow-hidden p-0'>
        <div className='container'>
        <Navbar expand="lg" className="navbar">
  <Container>
    {/* Left-aligned logo */}
    <Navbar.Brand href="#home">
      <img
        src="./images/DoorPe_Logo.png"
        className="doorpe-logo"
        alt="Logo"
      />
    </Navbar.Brand>

    {/* Toggle button for mobile view */}
    <Navbar.Toggle aria-controls="basic-navbar-nav" />

    {/* Navbar content */}
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="w-100 d-flex align-items-center justify-content-between">
        {/* Center-aligned logo */}
        <div className="mx-auto doorpe-icon">
          <img
            src="./images/doorpe_center_logo.png"
            className="doorpe-logo"
            alt="Center Logo"
          />
        </div>

        {/* Right-aligned navigation links */}
        <div className=" ml-auto right-nav-item">
          <Nav.Link
            className="nav-item-color nav-item1 business-link"
       
          >
            Business
          </Nav.Link>
          <Nav.Link
            className="nav-item-color nav-item2"
         
          >
            Mwhala
          </Nav.Link>
          <div
            className="d-flex justify-content-center align-items-center text-center Login-btn"
            style={{ cursor: "pointer" }}
          >
            <span className="login-app" >
              Login
            </span>
          </div>
        </div>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

    </div>
    <HomePage selectedIndex={selectedIndex}
    setSelectedIndex={setSelectedIndex}/>
    </section>
  );
};

export default Header;
